import React, { FunctionComponent } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { COLORS, DATE_FORMAT, STATE_RACE_ID } from '../../../../constants';
import Bubble from '../../Bubble';
import { isGovernorRace, isMayoralRace, isStateRace, routeToSelectedRace } from '../../../../utils';
import { useHistory, useParams } from 'react-router-dom';
import { ParamTypes } from '../../../../models/param-types';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    color: COLORS.darkGray,
    cursor: 'pointer',
    padding: '12px 27px 18px 27px',
    '&:hover': {
      boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
    },
  },
  title: {
    fontSize: '20px',
    fontWeight: 'bold',
    marginBottom: '5px',
  },
  innerContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  date: {
    fontSize: '16px',
    fontWeight: 'bold',
  },
}));

type RaceProps = {
  row?: any;
};

const Race: FunctionComponent<RaceProps> = ({ row }) => {
  const classes = useStyles();
  const { datasetId = STATE_RACE_ID } = useParams<ParamTypes>();
  const history = useHistory();

  let raceName;
  if (isStateRace(row)) {
    if (isGovernorRace(row)) {
      raceName = `${row.state} ${row.raceLabel}`;
    } else if (isMayoralRace(row)) {
      raceName = `${row.city_name}, ${row.state} ${row.raceLabel}`;
    } else {
      raceName = `${row.state} ${row.raceLabel} ${row.district}`;
    }
  } else {
    raceName = `${row.state}-${row.raceLabel}`;
  }

  return (
    <div className={classes.container} onClick={() => routeToSelectedRace(row.key, history, datasetId)}>
      <div className={classes.title}>{raceName}</div>
      <div className={classes.innerContainer}>
        <Bubble row={row} />
        <div className={classes.date}>{row.dt.format(DATE_FORMAT)}</div>
      </div>
    </div>
  );
};

export default Race;
