import React, { FunctionComponent } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { COLORS } from '../../constants';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../redux/store';

const useStyles = makeStyles((theme) => ({
  tooltip: {
    textAlign: 'center',
    zIndex: 1000,
    position: 'absolute',
    display: 'block',
    pointerEvents: 'none',
  },
  tooltipContainer: {
    position: 'absolute',
    padding: '8px 10px',
    top: '150px',
    width: '125px',
    lineHeight: '18px',
    background: COLORS.white,
    color: 'darkgray',
    boxShadow: '0 0 5px 0 rgba(0, 0, 0, 0.7)',
    MozBoxShadow: '0 0 5px 0 rgba(0, 0, 0, 0.7)',
    border: 'solid 1px #ddd',
  },
  tooltipLabel: {
    color: COLORS.darkGray,
    fontSize: '18px',
  },
}));

interface StateProps {
  activeMarker: any;
}

const Tooltip: FunctionComponent = () => {
  const classes = useStyles();
  const { activeMarker } = useSelector<ApplicationState, StateProps>((state: ApplicationState) => {
    return {
      activeMarker: state.global.activeMarker,
    };
  });

  if (!activeMarker) return null;

  let { top, left } = activeMarker;

  return (
    <div className={classes.tooltip} style={{ top: top - 25, left }}>
      <div className={classes.tooltipContainer}>
        <div className={classes.tooltipLabel}>{activeMarker?.label}</div>
      </div>
    </div>
  );
};

export default Tooltip;
