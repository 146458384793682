import React, { FunctionComponent } from 'react';
import { Modal } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { COLORS } from '../../constants';
import CloseIcon from '@material-ui/icons/Close';
import CleanData from './CleanData';
import CollectData from './CollectData';
import Regression from './Regression';
import Weights from './Weights';
import Project from './Project';
import ScoreCards from './ScoreCards';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  close: {
    position: 'absolute',
    top: 0,
    right: 0,
    width: '60px',
    height: '60px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: COLORS.blue,
    cursor: 'pointer',
    '&:hover': {
      background: COLORS.darkGray,
    },
  },
  closeIcon: {
    color: COLORS.white,
    fontSize: '50px',
  },
  paper: {
    position: 'relative',
    width: '80%',
    maxWidth: '800px',
    textAlign: 'left',
    backgroundColor: COLORS.white,
    boxShadow: '0 0 40px rgba(0, 0, 0, 0.25)',
    outline: 0,
    borderRadius: '10px',
    '& h2': {
      fontSize: '32px',
      color: COLORS.blue,
      fontWeight: 'bold',
      marginBottom: '15px',
    },
    '& p': {
      fontSize: '17px',
      color: COLORS.darkGray,
      lineHeight: '26px',
      margin: '0 0 20px 0',
    },
    '& h4': {
      fontSize: '22px',
      color: COLORS.darkGray,
      fontWeight: 'bold',
      marginBottom: '10px',
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
      width: '100%',
      height: '100%',
      boxShadow: 'none',
      borderRadius: 0,
    },
  },
  topLevelItem: {
    fontWeight: 'bold',
  },
  content: {
    maxHeight: '600px',
    overflowY: 'auto',
    padding: '15px 70px 30px 50px',
    [theme.breakpoints.down('sm')]: {
      maxHeight: '100%',
      height: '100%',
    },
  },
}));

type AboutTheDataProps = {
  open: boolean;
  setOpen: any;
};

const AboutTheData: FunctionComponent<AboutTheDataProps> = ({ open, setOpen }) => {
  const classes = useStyles();

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      className={classes.modal}
      aria-labelledby="about-the-grassroots-analytics-data"
      aria-describedby="A description of the process for how Grassroots Analytics assigns grades to candidates"
    >
      <div className={classes.paper}>
        <div className={classes.close}>
          <CloseIcon className={classes.closeIcon} onClick={() => setOpen(false)} />
        </div>
        <div className={classes.content}>
          <h2>Six Steps to Our Model</h2>
          <ol>
            <li>Collect data on the past four cycles of Democratic primaries</li>
            <li>Clean the data</li>
            <li>Run regression analysis to identify variable coefficient effects on vote share in each cycle and in parts of cycles</li>
            <li>Weight cycle coefficient effects and partial cycle coefficient effects</li>
            <li>
              Gather current cycle data in order to project vote share, uncertainty, and chances a candidate wins their Democratic primary
            </li>
            <li>Build categorical “scores” to explain each candidate’s forecast</li>
          </ol>
          <ol>
            <li className={classes.topLevelItem}>Collect Data</li>
            <CollectData />
            <li className={classes.topLevelItem}>Clean Data</li>
            <CleanData />
            <li className={classes.topLevelItem}>Run Regression Analysis and Coefficients</li>
            <Regression />
            <li className={classes.topLevelItem}>Weight Past vs. Present Cycles</li>
            <Weights />
            <li className={classes.topLevelItem}>Project</li>
            <Project />
            <li className={classes.topLevelItem}>Score Cards</li>
            <ScoreCards />
          </ol>
        </div>
      </div>
    </Modal>
  );
};

export default AboutTheData;
