import React, { FunctionComponent } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { ExtendedFeatureCollection, geoPath } from 'd3-geo';
import { ALL_STATE_META, COLORS, STATE_RACE_ID } from '../../constants';
import { nest } from 'd3-collection';
import ActivePath from './ActivePath';
import Paths from './Paths';
import { Dataset } from '../../models/dataset';
import { ensure } from '../../utils';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../redux/store';
import { Feature } from '../../models/feature';
import { Margin } from '../../models/margin';
import { useHistory, useParams } from 'react-router-dom';
import { ParamTypes } from '../../models/param-types';

const useStyles = makeStyles((theme) => ({
  text: {
    fill: COLORS.darkGray,
    stroke: 'none',
    fontSize: '13px',
    textTransform: 'uppercase',
    textAnchor: 'middle',
    pointerEvents: 'none',
    '&.cd': {
      textAnchor: 'start',
    },
  },
}));

type MapProps = {
  innerWidth?: number;
  innerHeight?: number;
  margin?: Margin;
  dataset?: Dataset;
  activeRace?: any;
  upcomingRace?: boolean;
};

interface StateProps {
  data: any;
}

const Map: FunctionComponent<MapProps> = ({ innerWidth, innerHeight, margin, dataset }) => {
  const classes = useStyles();
  const { data } = useSelector<ApplicationState, StateProps>((state: ApplicationState) => {
    return {
      data: state.global.data,
    };
  });
  const history = useHistory();
  const params = new URLSearchParams(history.location.search);
  const upcomingRace = params.get('upcomingRace') === 'true';
  const { activeRaceId = null, datasetId = STATE_RACE_ID } = useParams<ParamTypes>();
  const activeRace = data.find((row: any) => row.key === activeRaceId);

  const path = geoPath().projection(
    dataset?.projection.fitExtent(
      [
        [margin?.left, margin?.top],
        [innerWidth as number, innerHeight as number],
      ],
      dataset?.topology,
    ),
  );

  // Nest features by state for displaying text for congressional districts
  const statesForLabels = nest()
    .key((d: any) => d.stateAbbr)
    .entries(dataset ? dataset?.features : []);

  let selectedFeature = dataset?.features.find((feature: Feature) => {
    const { active, key } = feature;
    return active && activeRace && key === activeRace.key;
  });

  return (
    <g className="map-container">
      <Paths features={dataset?.features} upcomingRace={upcomingRace} path={path} />
      {selectedFeature && (
        <g className="active-path">
          <ActivePath path={path} feature={selectedFeature} datasetId={datasetId} />
        </g>
      )}
      {dataset?.id === STATE_RACE_ID ? (
        <g className="state-map-labels">
          {dataset?.features
            .filter((feature) => {
              return (!upcomingRace && feature.active) || (upcomingRace && feature.upcoming);
            })
            .map((feature) => {
              const { id } = feature;
              const centroid = path.centroid(feature.geometry);
              const stateObject = ensure(ALL_STATE_META.find((d) => d.fips === id));

              if (!centroid[0]) return null;

              // Custom translation for the state labels
              const { top, right, left, bottom } = stateObject;

              return (
                <text
                  transform={`translate(${centroid[0] + right - left},${centroid[1] - top + bottom})`}
                  key={`label-for-${id}`}
                  className={classes.text}
                >
                  {stateObject.abbr}
                </text>
              );
            })}
        </g>
      ) : (
        <g className="congressional-map-labels">
          {statesForLabels
            .filter((row: any) => {
              return row.values.some((feature: any) => (!upcomingRace && feature.active) || (upcomingRace && feature.upcoming));
            })
            .map((row: any) => {
              const id = row.key;

              // Get the bounds of all the features in the congressional district state set
              const featureCollection = {
                type: 'FeatureCollection',
                features: row.values,
              } as ExtendedFeatureCollection;
              const bounds = path.bounds(featureCollection);

              // Handle translation based on manual CD label placements
              const stateObject = ensure(ALL_STATE_META.find((d) => d.abbr === id));

              let translation = [0, 0];
              const { topCD, rightCD, leftCD, bottomCD, positionCD } = stateObject;
              if (positionCD === 'bottom-right') {
                translation = [bounds[1][0], bounds[1][1]];
              } else if (positionCD === 'top-right') {
                translation = [bounds[1][0], bounds[0][1]];
              }
              translation = [translation[0] + rightCD - leftCD, translation[1] - topCD + bottomCD];

              return (
                <text transform={`translate(${translation[0]},${translation[1]})`} key={`label-for-${id}`} className={`${classes.text} cd`}>
                  {id}
                </text>
              );
            })}
        </g>
      )}
    </g>
  );
};

export default Map;
