import { Dataset } from '../../../models/dataset';
import { Feature } from '../../../models/feature';

export enum GlobalActionTypes {
  FETCH_SUCCESS = '@@global/FETCH_SUCCESS',
  FETCH_ERROR = '@@global/FETCH_ERROR',
  FETCH_REQUEST = '@@global/FETCH_REQUEST',
  SET_ACTIVE_MARKER = '@@global/SET_ACTIVE_MARKER',
}

export interface GlobalState {
  readonly data: any;
  readonly errors?: string;
  readonly loading: boolean;
  readonly datasets: Dataset[];
  readonly activeMarker?: Feature;
}
