import React from 'react';

const Regression = () => {
  return (
    <ul>
      <li>
        <span>Significant Independent Variable effect on vote share (most significant to least; p-values &lt;0.100)</span>
      </li>
      <ul>
        <li>
          <span>Fundraising &ndash; Very significant positive coefficient</span>
        </li>
        <ul>
          <li>
            <span>
              The percent of money you have access to in the election (raise + cash on hand + potential independent expenditure support)
              relative to your opponent
            </span>
          </li>
        </ul>
        <li>
          <span>Incumbency &ndash; Significant positive coefficient</span>
        </li>
        <li>
          <span>Self-Funder &ndash; Significant negative coefficient</span>
        </li>
        <ul>
          <li>
            <span>Whether or not the candidate self-funded over 25% of their accessible money in the Democratic primary</span>
          </li>
        </ul>
        <li>
          <span>Woman Identifying Candidates &ndash; Significant positive coefficient</span>
        </li>
        <li>
          <span>Singular Progressive Candidate &ndash; Significant positive coefficient</span>
        </li>
        <ul>
          <li>
            <span>
              If the candidate is the only viable (based on fundraising coefficient) progressive in the race, they receive a vote share
              boost
            </span>
          </li>
        </ul>
        <li>
          <span>One-of-many Progressive Candidates &ndash; Significant negative coefficient</span>
        </li>
        <ul>
          <li>
            <span>If the candidate is one of many viable progressives in the race, they receive a vote share drop</span>
          </li>
        </ul>
        <li>
          <span>Under 40 years old &ndash; Significant negative coefficient</span>
        </li>
        <ul>
          <li>
            <span>If the candidate is under 40 years of age, they receive a drop in vote share</span>
          </li>
        </ul>
      </ul>
      <li>
        <span>Significant Interaction Variables (p-value &lt;0.100)</span>
      </li>
      <ul>
        <li>
          <span>Interaction variables for Black, Latinx, or AAPI Candidates &ndash; Significant positive coefficient effects</span>
        </li>
        <ul>
          <li>
            <span>
              An interaction variable that factors candidate demographics, percent of the district that shares the candidate&rsquo;s
              demographic, population in a district, and the demographics of other candidates in a primary
            </span>
          </li>
        </ul>
      </ul>
      <li>
        <span>Insignificant Interaction Variables (p-value &gt;=0.100)</span>
      </li>
      <ul>
        <li>
          <span>Interaction variables for LGBTQ, Jewish and Muslim candidates do not show a significant impact on vote share</span>
        </li>
      </ul>
    </ul>
  );
};

export default Regression;
