import React, { FunctionComponent } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { COLORS } from '../../constants';
import twitterLogo from './../../assets/images/twitter-logo.svg';
import emailIcon from './../../assets/images/email-icon.svg';

const useStyles = makeStyles((theme) => ({
  footer: {},
  aboutContainer: {
    color: COLORS.blue,
    fontSize: '14px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    cursor: 'pointer',
    margin: '5px 0',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      '& :not(:last-child)': {
        marginBottom: '5px',
      },
    },
  },
  innerAboutContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: '20px',
  },
  about: {
    textDecoration: 'underline',
    paddingLeft: '5px',
  },
  creditText: {
    margin: '5px 0',
    color: '#7e8b8b',
    fontStyle: 'italic',
    fontSize: '12px',
  },
}));

type AboutLinksProps = {
  setOpen: any;
};

const AboutLinks: FunctionComponent<AboutLinksProps> = ({ setOpen }) => {
  const classes = useStyles();

  return (
    <div className={classes.footer}>
      <div className={classes.aboutContainer}>
        <div className={classes.about} onClick={() => setOpen(true)}>
          About our methodology
        </div>
        <div className={classes.innerAboutContainer}>
          <img src={twitterLogo} alt="Blue Twitter bird to tweet to PrimaryCast account" />
          <a href="https://twitter.com/PrimaryCast" target="_blank" rel="noreferrer">
            <div className={classes.about}>Follow Us</div>
          </a>
        </div>
        <div className={classes.innerAboutContainer}>
          <img src={emailIcon} alt="Envelope to represent how to contact the Grassroots team." />
          <a href="mailto:danny@grassrootsanalytics.com">
            <div className={classes.about}>Want a custom model?</div>
          </a>
        </div>
      </div>
    </div>
  );
};

export default AboutLinks;
