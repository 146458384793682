import React, { FunctionComponent } from 'react';
import Bubble from '../../Bubble';
import { DATE_FORMAT, LONG_DATE_FORMAT, PRIMARY_DATE_FORMAT_READABLE, STATE_RACE_ID } from '../../../../constants';
import CustomSelect from '../../CustomSelect';
import { useHistory, useParams } from 'react-router-dom';
import { ParamTypes } from '../../../../models/param-types';
import { isGovernorRace, isMayoralRace } from '../../../../utils';
import { merge } from 'd3-array';

type StateTopContainerProps = {
  candidate: any;
  classes: any;
  matchingRaces: any[];
};

const StateTopContainer: FunctionComponent<StateTopContainerProps> = ({ candidate, matchingRaces, classes }) => {
  const { datasetId = STATE_RACE_ID } = useParams<ParamTypes>();
  const history = useHistory();

  // Is it a run-off?
  const isRunOff: boolean = candidate.runoff;

  // Handle select dropdown label
  const label = 'Select another primary for this state';

  // Get all races - there may be some nested races with different dates
  const allRaces: any[] = merge(matchingRaces.map((d: any) => d.values));

  // Get dropdown options
  const optionAccessor = (d: any) => {
    if (isMayoralRace(d)) {
      return `${d.city_name} ${d.office} ${d.dt.format(DATE_FORMAT)}`;
    } else if (isGovernorRace(d)) {
      return `${d.office} ${d.dt.format(DATE_FORMAT)}`;
    }
    return `${d.office} ${d.district} ${d.dt.format(DATE_FORMAT)}`;
  };
  const options: string[] = Array.from(new Set(allRaces.map(optionAccessor)));

  // Handle dropdown on change
  const onChange = (id: string) => {
    const matchingRace = allRaces.find((matchingRace) => optionAccessor(matchingRace) === id);
    return history.push({
      pathname: `/${datasetId}/${matchingRace.state_dist}/${matchingRace.dt.format(PRIMARY_DATE_FORMAT_READABLE)}`,
      search: history.location.search,
    });
  };

  let raceLabel;
  if (isGovernorRace(candidate)) {
    raceLabel = candidate.raceLabel;
  } else if (isMayoralRace(candidate)) {
    // mayoral race
    raceLabel = `${candidate.city_name} ${candidate.raceLabel}`;
  } else {
    raceLabel = `${candidate.raceLabel} ${candidate.district}`;
  }

  return (
    <div className={classes.topContainer}>
      <div className={classes.title}>{candidate.stateName}</div>
      <div className={classes.subTitle}>{raceLabel}</div>
      <Bubble row={candidate} />
      <div className={classes.date}>
        Primary Date {isRunOff ? '(RUNOFF)' : ''} : <span>{candidate.dt.format(LONG_DATE_FORMAT)}</span>
      </div>
      <div className={classes.selectDropdown}>
        {options.length > 1 && <CustomSelect label={label} selectedItem={optionAccessor(candidate)} items={options} onChange={onChange} />}
      </div>
      <div className={classes.chanceOfWinning}>Chance of Winning (click to expand)</div>
    </div>
  );
};

export default StateTopContainer;
