import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { COLORS, COMPETITIONS } from '../../constants';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: '15px 30px',
  },
  legendBlock: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    '& :last-child': {
      marginRight: '28px',
    },
  },
  square: {
    width: '14px',
    height: '14px',
    marginRight: '5px',
  },
  legendItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  text: {
    textTransform: 'uppercase',
    fontSize: '14px',
    color: COLORS.darkGray,
  },
  title: {
    fontSize: '18px',
    fontWeight: 'bold',
    color: COLORS.darkGray,
    padding: '10px 0',
  },
  subTitle: {
    fontSize: '14px',
    color: COLORS.darkGray,
    fontStyle: 'italic',
    padding: '10px 0',
  },
}));

const Legend = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.title}>How competitive are the upcoming Democratic primary races?</div>
      <div className={classes.legendBlock}>
        {COMPETITIONS.map((d) => {
          return (
            <div key={`legend-item-for-${d.id}`} className={classes.legendItem}>
              <div className={classes.square} style={{ backgroundColor: d.color }} />
              <div className={classes.text}>{d.label}</div>
            </div>
          );
        })}
      </div>
      <div className={classes.subTitle}>Upcoming races are outlined in white</div>
    </div>
  );
};

export default Legend;
