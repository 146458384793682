import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import grassrootsLogo from '../../assets/images/Grassroots+Analytics-resized@3x.png';
import graphicacyLogo from '../../assets/images/Graphicacy+Logo@3x.png';
import { COLORS } from '../../constants';

const useStyles = makeStyles((theme) => ({
  header: {
    height: '81px',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '15px 25px',
    borderBottom: `4px ${COLORS.blue} solid`,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
      height: 'auto',
    },
  },
  title: {
    fontSize: '36px',
    fontWeight: 'bold',
    lineHeight: '1.42',
    letterSpacing: 'normal',
    textAlign: 'left',
    color: COLORS.darkGray,
    [theme.breakpoints.down('sm')]: {
      marginBottom: '15px',
    },
  },
  right: {
    display: 'flex',
    flexDirection: 'row',
    color: COLORS.darkGray,
    fontSize: '14px',
    textTransform: 'uppercase',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  imageBlock: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: '40px',
    '& a': {
      display: 'inline-block',
      marginBottom: '-5px'
    },
    '& img': {
      marginLeft: '15px'
    },
    '& span': {
      display: 'inline-block',
    },
    [theme.breakpoints.down('sm')]: {
      margin: '6px 0',
    },
  },
  poweredBy: {
    marginTop: '-14px',
    height: '36px'
  },
  logo: {
    height: '20px'
  }
}));

const Header = () => {
  const classes = useStyles();

  return (
    <div className={classes.header}>
      <div className={classes.title}>PrimaryCast</div>
      <div className={classes.right}>
        <div className={classes.imageBlock}>
          <span>Powered By</span>
          <a href="https://www.grassrootsanalytics.com/" target="_blank" rel="noreferrer">
            <img
              alt="Grassroots analytics text behind a patch of grass."
              className={classes.poweredBy}
              src={grassrootsLogo}
            />
          </a>
        </div>
        <div className={classes.imageBlock}>
          <span>Built by</span>
          <a href="https://graphicacy.com" target="_blank" rel="noreferrer">
            <img alt="A brand-mark for Graphicacy - the data visualization and design firm."
                 className={classes.logo}
                 src={graphicacyLogo} />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Header;
