import React from 'react';

const ScoreCards = () => {
  return (
    <ul>
      <li>Candidate Scores</li>
      <ul>
        <li>Fundraising</li>
        <ul>
          <li>An index that represents a candidate&rsquo;s fundraising total in comparison to:</li>
          <ul>
            <li>Average regional fundraising totals</li>
            <li>Average incumbent/challenger/open fundraising totals</li>
            <li>The cost of paid media in their district</li>
            <li>Other candidates in their Democratic primary</li>
          </ul>
        </ul>
        <li>Demographic Match</li>
        <ul>
          <li>
            An index that represents a candidate&rsquo;s demographic match with their district (including in comparison to other viable
            candidates in their Democratic primary). Demographic match includes candidate:
          </li>
          <ul>
            <li>Gender</li>
            <li>Race</li>
            <li>Age</li>
            <li>Religion</li>
          </ul>
        </ul>
        <li>Name Recognition</li>
        <ul>
          <li>An index that represents a candidate&rsquo;s expected name recognition in their district through:</li>
          <ul>
            <li>
              The amount of money the candidate has spent in their district over the past four cycles (weighted by cost of paid media)
            </li>
            <li>Incumbency status</li>
            <li>District changes (i.e., map changes and/or population movement)</li>
          </ul>
        </ul>
        <li>Organizational Support</li>
        <ul>
          <li>An index that represents the value of a candidate&rsquo;s:</li>
          <ul>
            <li>Endorsements</li>
            <li>Support from other elected officials</li>
            <li>PAC support</li>
            <li>Support from organizations that are known to provide independent expenditure support in Democratic primaries</li>
            <li>Support from progressive activists</li>
          </ul>
        </ul>
      </ul>
    </ul>
  );
};

export default ScoreCards;
