import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { COLORS, DURATION, STATE_RACE_ID } from '../../../../constants';
import { useDispatch } from 'react-redux';
import { pointer } from 'd3-selection';
import { setActiveMarker } from '../../../../redux/store/global/actions';
import { Feature } from '../../../../models/feature';
import { useHistory, useParams } from 'react-router-dom';
import { ParamTypes } from '../../../../models/param-types';
import { routeToSelectedRace } from '../../../../utils';

const useStyles = makeStyles(() => ({
  path: {
    strokeWidth: '2px',
    transition: `${DURATION}ms fill`,
  },
}));

type PathProps = {
  feature: Feature;
  d: string;
};

const Path: FunctionComponent<PathProps> = ({ feature, d }) => {
  const classes = useStyles();
  const { datasetId = STATE_RACE_ID } = useParams<ParamTypes>();
  const [currentColor, setCurrentColor] = useState(COLORS.white as string);
  const dispatch = useDispatch();
  const activateMarker = useCallback((feature: any) => dispatch(setActiveMarker(feature)), [dispatch]);
  const history = useHistory();

  const { color, active, key, upcoming } = feature;

  useEffect(() => {
    setCurrentColor(color);
  }, [color]);

  const onMouseOver = (evt: any) => {
    const [left, top] = pointer(evt);
    activateMarker({
      ...feature,
      top,
      left,
    });
  };

  const onMouseOut = () => active && activateMarker(null);

  return (
    <path
      transform={`scale(1)`}
      className={classes.path}
      d={d}
      onClick={() => active && routeToSelectedRace(key, history, datasetId)}
      onMouseOver={(evt) => active && onMouseOver(evt)}
      onMouseOut={onMouseOut}
      style={{
        cursor: active ? 'pointer' : 'default',
        fill: currentColor,
        stroke: upcoming ? COLORS.white : 'none',
      }}
    />
  );
};

export default Path;
