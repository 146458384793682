import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Header from './Header';
import Spinner from './Spinner';
import BaseChart from './BaseChart';
import { fetchRequest } from '../redux/store/global/actions';
import { ApplicationState } from '../redux/store';
import Legend from './Legend';
import { COOKIE_KEY, STATE_RACE_ID } from '../constants';
import DatasetToggle from './DatasetToggle';
import UpcomingRaces from './UpcomingRaces';
import { Dataset } from '../models/dataset';
import SidebarContainer from './SidebarContainer';
import { ensure } from '../utils';
import AboutTheData from './AboutTheData';
import Map from './Map';
import Tooltip from './Tooltip';
import { useParams } from 'react-router-dom';
import { ParamTypes } from '../models/param-types';
import MapFooter from './MapFooter';
import EmailCollectionModal from './EmailCollectionModal';
import Cookies from 'universal-cookie';

const useStyles = makeStyles((theme) => ({
  app: {
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    [theme.breakpoints.down('sm')]: {
      overflowY: 'auto',
    },
  },
  visualizationContainer: {
    height: 'calc(100% - 81px)',
    width: '100%',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      height: 'auto',
    },
  },
  toggleContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    position: 'relative',
    [theme.breakpoints.down(1380)]: {
      flexDirection: 'column',
    },
  },
  mapContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: 'calc(100% - 398px)',
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  map: {
    height: '100%',
    width: '100%',
  },
}));

interface StateProps {
  loading: boolean;
  datasets: Dataset[];
}

const App: FunctionComponent = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { datasetId = STATE_RACE_ID } = useParams<ParamTypes>();
  const getData = useCallback(() => dispatch(fetchRequest()), [dispatch]);
  const { loading, datasets } = useSelector<ApplicationState, StateProps>((state: ApplicationState) => {
    return {
      loading: state.global.loading,
      datasets: state.global.datasets,
    };
  });
  const [open, setOpen] = useState(false);
  const [emailModalOpen, setEmailModalOpen] = useState(true);

  // Filter the data based on the dataset view
  const selectedDataset: Dataset = useMemo(() => ensure(datasets.find((d) => d.id === datasetId)), [datasets, datasetId]);

  useEffect(() => {
    const cookies = new Cookies();
    // cookies.remove(COOKIE_KEY, {path: '/'})
    if (cookies.get(COOKIE_KEY) === 'true') {
      setEmailModalOpen(false);
    }
    getData();
  }, [getData]);

  return (
    <div className={classes.app}>
      <Header />
      {loading ? (
        <Spinner />
      ) : (
        <div className={classes.visualizationContainer}>
          <div className={classes.mapContainer}>
            <div className={classes.toggleContainer}>
              <DatasetToggle />
              <UpcomingRaces />
            </div>
            <Legend />
            <BaseChart className={classes.map} dataset={selectedDataset}>
              <Map />
            </BaseChart>
          </div>
          <SidebarContainer setOpen={setOpen} selectedDataset={selectedDataset} />
          <MapFooter selectedDataset={selectedDataset} setOpen={setOpen} />
          <Tooltip />
        </div>
      )}
      <AboutTheData open={open} setOpen={setOpen} />
      <EmailCollectionModal open={emailModalOpen} setOpen={setEmailModalOpen} />
    </div>
  );
};

export default App;
