import React, { PureComponent } from 'react';
import Path from './Path';
import { COLORS } from '../../../constants';
import { Feature } from '../../../models/feature';

type PathsProps = {
  path?: any;
  features?: Feature[];
  upcomingRace?: boolean;
};

class Paths extends PureComponent<PathsProps> {
  render() {
    const { features, path, upcomingRace } = this.props;
    return (
      <g className="shape-file-paths">
        {features &&
          features.map((feature: Feature) => {
            const { id, upcoming, color, active } = feature;

            // Handle coloring of features based on if they are upcoming
            let featureColor = COLORS.white as string;
            let featureActive = false;
            if ((!upcomingRace && active) || (upcomingRace && upcoming)) {
              featureColor = color;
              featureActive = true;
            }
            return (
              <Path
                key={`path-for-${id}`}
                d={path(feature) as string}
                feature={{
                  ...feature,
                  color: featureColor,
                  active: featureActive,
                }}
              />
            );
          })}
      </g>
    );
  }
}

export default Paths;
