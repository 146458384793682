import { useCallback, useLayoutEffect, useState } from 'react';
import debounce from 'lodash.debounce';
import moment from 'moment';
import 'whatwg-fetch';
import { RACES } from './constants';

export const idAccessor = (d: any) => d.state_dist;
export const activeRaceDateAccessor = (d: any) => d.primary_date;
export const congressionalDistrictIdAccessor = (feature: any) => `${feature.properties.STATEAB}_${feature.properties.CDLABEL}`;
export const isStateRace = (row: any) => [RACES.Senate, RACES.Governor, RACES.Mayor, RACES.LieutenantGovernor].includes(row.office);
export const isHouseRace = (row: any) => [RACES.House].includes(row.office);
export const isGovernorRace = (row: any) => [RACES.Governor, RACES.LieutenantGovernor].includes(row.office);
export const isMayoralRace = (row: any) => [RACES.Mayor].includes(row.office);
export const breakApartStateDist = (row: any) => row.state_dist.split('_').slice(0, 2).join('_');

export function getNumberWithOrdinal(n: number) {
  const s = ['th', 'st', 'nd', 'rd'];
  const v = n % 100;
  return n + (s[(v - 20) % 10] || s[v] || s[0]);
}

export const getUrlParams = (search = '') => {
  let hashes = search.slice(search.indexOf('?') + 1).split('&');
  return hashes.reduce((params, hash) => {
    let [key, val] = hash.split('=');
    return val !== undefined ? Object.assign(params, { [key]: decodeURIComponent(val) }) : params;
  }, {});
};

export const isUpcomingRace = (d: any) => {
  const dt = d.dt.clone().add(1, 'day').endOf('day');
  return dt.isSameOrAfter(moment().endOf('day'));
};

function getDimensionObject(node: any) {
  const rect = node.getBoundingClientRect();

  return {
    width: rect.width,
    height: rect.height,
    top: 'x' in rect ? rect.x : rect.top,
    left: 'y' in rect ? rect.y : rect.left,
    x: 'x' in rect ? rect.x : rect.left,
    y: 'y' in rect ? rect.y : rect.top,
    right: rect.right,
    bottom: rect.bottom,
  };
}

export const useDimensions = (initialDimensions: any) => {
  const [dimensions, setDimensions] = useState(initialDimensions);
  const [node, setNode] = useState(null);

  const ref = useCallback((node) => {
    setNode(node);
  }, []);

  useLayoutEffect(() => {
    if (node) {
      const measure = debounce(
        () => window.requestAnimationFrame(() => setDimensions({ ...getDimensionObject(node), isResized: true })),
        250,
      );
      measure();

      window.addEventListener('resize', measure);

      return () => {
        window.removeEventListener('resize', measure);
      };
    }
  }, [node]);

  return [ref, dimensions, node];
};

export function ensure<T>(argument: T | undefined | null, message: string = 'This value was promised to be there.'): T {
  if (argument === undefined || argument === null) {
    throw new TypeError(message);
  }
  return argument;
}

export const routeToSelectedRace = (id: string, history: any, datasetId: string) => {
  return history.push({
    pathname: `/${datasetId}/${id}`,
    search: history.location.search,
  });
};

export async function postData(url = '', data = {}) {
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
  return response.json(); // parses JSON response into native JavaScript objects
}
