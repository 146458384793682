import { combineReducers } from 'redux';
import { all, fork } from 'redux-saga/effects';
import { globalReducer } from './global/reducer';
import { GlobalState } from './global/types';
import globalSaga from './global/sagas';

export interface ApplicationState {
  global: GlobalState;
}

export const createRootReducer = () =>
  combineReducers({
    global: globalReducer,
  });

export function* rootSaga() {
  yield all([fork(globalSaga)]);
}
