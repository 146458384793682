import React, { FunctionComponent, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { COLORS, PRIMARY_DATE_FORMAT_READABLE, STATE_RACE_ID } from '../../../constants';
import CandidateProfile from './CandidateProfile';
import { scaleLinear } from 'd3-scale';
import { descending } from 'd3-array';
import { activeRaceDateAccessor, isUpcomingRace } from '../../../utils';
import CloseIcon from '@material-ui/icons/Close';
import About from '../About';
import { useHistory, useParams } from 'react-router-dom';
import { ParamTypes } from '../../../models/param-types';
import moment from 'moment';
import CDTopContainer from './CDTopContainer';
import StateTopContainer from './StateTopContainer';

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  topContainer: {
    padding: '40px 27px 0 27px',
  },
  close: {
    position: 'absolute',
    top: 0,
    right: 0,
    width: '37px',
    height: '37px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: COLORS.blue,
    cursor: 'pointer',
    '&:hover': {
      background: COLORS.darkGray,
    },
  },
  closeIcon: {
    color: COLORS.white,
    fontSize: '32px',
  },
  title: {
    fontSize: '20px',
    textTransform: 'uppercase',
  },
  subTitle: {
    fontSize: '24px',
    fontWeight: 700,
    marginBottom: '20px',
  },
  date: {
    fontSize: '14px',
    textTransform: 'uppercase',
    '& span': {
      fontSize: '16px',
      fontWeight: 'bold',
    },
    margin: '20px 0',
  },
  selectDropdown: {
    paddingBottom: '15px',
    borderBottom: `1px ${COLORS.gray} solid`,
    margin: '20px 0',
  },
  chanceOfWinning: {
    fontStyle: 'italic',
    color: COLORS.blue,
    fontSize: '16px',
    marginBottom: '30px',
  },
  candidates: {
    overflowY: 'auto',
  },
}));

type SidebarProps = {
  row: any;
  matchingRaces: any[];
  setOpen: any;
};

const SidebarDetailed: FunctionComponent<SidebarProps> = ({ row, matchingRaces, setOpen }) => {
  const { datasetId = STATE_RACE_ID, activeRaceDate = null } = useParams<ParamTypes>();
  const classes = useStyles();
  const history = useHistory();
  const closeSidebar = () => {
    return history.push({
      pathname: `/${datasetId}`,
      search: history.location.search,
    });
  };

  // Get the selected race candidates based on active race date
  let candidates = [];
  if (activeRaceDate) {
    candidates = row.values.filter((d: any) => d.dt.isSame(moment(activeRaceDate, PRIMARY_DATE_FORMAT_READABLE)));
  } else {
    // Default to the most recent date, if one is not provided
    const newestValue: any = row.values.sort((a: any, b: any) => descending(a.dt, b.dt))[0];
    candidates = row.values.filter((d: any) => activeRaceDateAccessor(d) === activeRaceDateAccessor(newestValue));
  }

  // If there was a winner in the race, open their "drawer"
  const winningCandidate = candidates.find((d: any) => Boolean(+d.win));
  const [expandedCandidate, setExpandedCandidate] = useState(winningCandidate?.candidate);
  useEffect(() => {
    setExpandedCandidate(winningCandidate?.candidate);
  }, [winningCandidate?.candidate]);

  // If the user is searching for a primary date that doesn't exist
  if (!candidates.length) {
    history.push({
      pathname: `/${datasetId}`,
      search: history.location.search,
    });
    return null;
  }

  // Use the first candidate value to fill in other information
  const candidate = candidates[0];

  // Get line scale for all candidates
  const xScale = scaleLinear()
    .range([0, 300])
    .domain([0, Math.max(...candidates.map((d: any) => +d.chance_of_winning))]);

  // Is it an upcoming race?
  const isUpcoming: boolean = isUpcomingRace(candidate);

  return (
    <div className={classes.container}>
      <div className={classes.close}>
        <CloseIcon className={classes.closeIcon} onClick={() => closeSidebar()} />
      </div>
      {datasetId === STATE_RACE_ID ? (
        <StateTopContainer candidate={candidate} classes={classes} matchingRaces={matchingRaces} />
      ) : (
        <CDTopContainer row={row} candidate={candidate} classes={classes} />
      )}
      <div className={classes.candidates}>
        {candidates
          .sort((a: any, b: any) => descending(+a.chance_of_winning, +b.chance_of_winning))
          .map((d: any) => {
            return (
              <CandidateProfile
                key={`section-for-${d.candidate}`}
                row={{
                  ...d,
                  width: xScale(+d.chance_of_winning),
                }}
                isUpcoming={isUpcoming}
                isExpanded={expandedCandidate === d.candidate}
                setExpandedCandidate={setExpandedCandidate}
              />
            );
          })}
      </div>
      <About setOpen={setOpen} />
    </div>
  );
};

export default SidebarDetailed;
