import { GlobalActionTypes, GlobalState } from './types';
import { Reducer } from 'redux';
import moment from 'moment';
import { ALL_STATE_META, COLORS, COMPETITIONS, OFFICE_CATEGORY_LOOKUP, PRIMARY_DATE_FORMAT, STATE_RACE_ID } from '../../../constants';
import { nest } from 'd3-collection';
import {
  breakApartStateDist,
  congressionalDistrictIdAccessor,
  ensure,
  getNumberWithOrdinal,
  idAccessor,
  isMayoralRace,
  isStateRace,
  isUpcomingRace,
} from '../../../utils';
import { Dataset } from '../../../models/dataset';
import { descending } from 'd3-array';
import * as topojson from 'topojson-client';
import { GeometryObject, Topology } from 'topojson-specification';
import { ExtendedFeatureCollection, geoAlbersUsa, geoIdentity } from 'd3-geo';
import { Feature } from '../../../models/feature';

export const initialState: GlobalState = {
  data: [],
  loading: true,
  errors: undefined,
  datasets: [
    {
      id: STATE_RACE_ID,
      name: 'State-Wide Races',
      topology: {},
      topojsonObject: 'states',
      topojsonAccessor: 'states',
      features: [],
      projection: geoAlbersUsa(),
      labelAccessor: (d: any) =>
        d ? (isMayoralRace(d) ? `${d?.city_name}, ${d?.state} ${d?.raceLabel}` : `${d?.state} ${d?.raceLabel}`) : '',
      idAccessor: (d: any) => d.id,
      getMatchingRows: (xs: any[], id: string) => {
        const stateObject = ensure(ALL_STATE_META.find((d) => d.fips === id));
        return xs && xs.filter((row: any) => isStateRace(row) && row.state.toLowerCase() === stateObject.abbr.toLowerCase());
      },
    },
    {
      id: 'house-races',
      name: 'House Races',
      topology: {},
      topojsonObject: 'congressionalDistricts',
      topojsonAccessor: 'HexCDv21',
      features: [],
      projection: geoIdentity().reflectY(true),
      labelAccessor: (d: any) => (d ? `${d?.state}-${d?.district}` : ''),
      idAccessor: congressionalDistrictIdAccessor,
      getMatchingRows: (xs: any, id: string) => xs && xs.filter((row: any) => breakApartStateDist(row) === id),
    },
  ],
  activeMarker: undefined,
};

const formatData = (data: any[]) => {
  const xs = data.map((d: any) => {
    const [stateAbbr, district] = d.state_dist.split('_');
    const office = OFFICE_CATEGORY_LOOKUP(d.office, d.state_dist);
    const stateRace = isStateRace({
      ...d,
      office,
    });
    const stateObj = ensure(ALL_STATE_META.find((d: any) => d.abbr.toLowerCase() === stateAbbr.toLowerCase()));
    return {
      ...d,
      state: stateAbbr,
      district,
      runoff: !!d.run_off,
      incumbent: Boolean(+d.incumbent),
      stateName: stateObj.name,
      raceLabel: stateRace ? `${office}` : `${getNumberWithOrdinal(+district)} Congressional District`,
      office: OFFICE_CATEGORY_LOOKUP(d.office, d.state_dist),
      dt: moment(d.primary_date, PRIMARY_DATE_FORMAT),
    };
  });
  return nest()
    .key(idAccessor)
    .entries(xs)
    .map((d) => {
      const firstRow = d.values.sort((a: any, b: any) => descending(a.dt, b.dt))[0];
      return {
        ...d,
        ...firstRow,
        active: false,
      };
    });
};

const formatFeatures = (data: any, features: any, dataset: Dataset): Feature[] => {
  return features.map((feature: any) => {
    const id = dataset.idAccessor(feature);
    const matchingRows = dataset.getMatchingRows(data, id);

    // Do logic for which race to display - based on primary date
    let matchingRow: any = undefined;
    if (matchingRows && matchingRows.length === 1) {
      matchingRow = matchingRows[0];
    } else {
      matchingRow = matchingRows && matchingRows.sort((a: any, b: any) => descending(a.dt, b.dt))[0];
    }

    // Assign color based on competition
    let color = COLORS.white as string;
    let active = false;
    if (matchingRow) {
      color = ensure(COMPETITIONS.find((d) => d.id === matchingRow.uncontested)).color;
      active = true;
    }

    return {
      ...feature,
      color,
      active,
      id,
      stateAbbr: matchingRow ? matchingRow?.state : '',
      dt: matchingRow ? matchingRow?.dt : moment(),
      key: matchingRow ? matchingRow?.key : '',
      label: matchingRow ? dataset.labelAccessor(matchingRow) : '',
      upcoming: active && isUpcomingRace(matchingRow),
    };
  });
};

const reducer: Reducer<GlobalState> = (state = initialState, action) => {
  switch (action.type) {
    case GlobalActionTypes.FETCH_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case GlobalActionTypes.FETCH_SUCCESS:
      const data = formatData(action.payload.data);
      const datasets = state.datasets.map((dataset: Dataset) => {
        const topology: Topology = (action.payload[dataset.topojsonObject] as unknown) as Topology;
        const featureCollection = topojson.feature(
          topology,
          topology.objects[dataset.topojsonAccessor] as GeometryObject,
        ) as ExtendedFeatureCollection;
        return {
          ...dataset,
          topology: featureCollection,
          features: formatFeatures(data, featureCollection.features, dataset),
        };
      });
      return {
        ...state,
        loading: false,
        data,
        datasets,
      };
    case GlobalActionTypes.FETCH_ERROR:
      return {
        ...state,
        loading: false,
        errors: action.payload,
      };
    case GlobalActionTypes.SET_ACTIVE_MARKER:
      return {
        ...state,
        activeMarker: action.payload,
      };
    default:
      return state;
  }
};
export { reducer as globalReducer };
