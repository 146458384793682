import React from 'react';

const CollectData = () => {
  return (
    <ul>
      <li>Election Results</li>
      <ul>
        <li>
          Gather vote share results from the 2016 through 2022 cycles, including special elections and Democrat vs. Democrat special
          elections
        </li>
      </ul>
      <li>Candidate Data</li>
      <ul>
        <li>Gather data on every Independent or Democratic candidate that either made the ballot or raised $5,000+ in a federal race</li>
        <li>Gather candidate fundraising data, demographic data, and ideology data</li>
      </ul>
      <li>District Data</li>
      <ul>
        <li>
          Gather census data on the candidate’s district (congressional or state) including median income, demographics, and gender
          breakdown
        </li>
        <li>Gather partisanship data of the district or state</li>
      </ul>
      <li>Fundraising Data</li>
      <ul>
        <li>We gather fundraising data on a candidate’s fundraising total as of their primary election day</li>
      </ul>
    </ul>
  );
};

export default CollectData;
