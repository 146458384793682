import React, { FunctionComponent } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { COLORS, STATE_RACE_ID } from '../../constants';
import { Dataset } from '../../models/dataset';
import AboutLinks from '../AboutLinks';

const useStyles = makeStyles((theme) => ({
  mapFooter: {
    position: 'absolute',
    bottom: '20px',
    width: 'calc(100% - 398px)',
    padding: '0 30px',
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    [theme.breakpoints.down(1175)]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
    '& a': {
      color: COLORS.blue,
    },
  },
  creditText: {
    margin: '5px 0',
    color: '#7e8b8b',
    fontStyle: 'italic',
    fontSize: '12px',
  },
}));

type MapFooterProps = {
  selectedDataset: Dataset;
  setOpen: any;
};

const MapFooter: FunctionComponent<MapFooterProps> = ({ selectedDataset, setOpen }) => {
  const classes = useStyles();

  return (
    <div className={classes.mapFooter}>
      {selectedDataset.id !== STATE_RACE_ID && (
        <div className={classes.creditText}>
          Congressional District base map courtesy of{' '}
          <a href="https://dkel.ec/map" target="_blank" rel="noreferrer">
            Daily Kos
          </a>
        </div>
      )}
      <AboutLinks setOpen={setOpen} />
    </div>
  );
};

export default MapFooter;
