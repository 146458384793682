import React, { FunctionComponent } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { MenuItem, FormControl, Select, InputBase, Typography } from '@material-ui/core';
import { COLORS } from '../../../constants';

const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
      fontSize: '14px',
      color: COLORS.darkGray,
    },
  },
  input: {
    padding: '6.5px 26px 6.5px 16px',
  },
}))(InputBase);

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginRight: theme.spacing(1),
    width: '100%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  label: {
    '&.Mui-focused': {
      color: '#000',
    },
  },
  select: {
    borderRadius: 5,
    boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.2)',
    '.MuiSelect-select': {
      paddingLeft: theme.spacing(1),
    },
    '&:hover:not(.Mui-disabled):before, &:before': {
      display: 'none',
    },
    '&:after': {
      display: 'none',
    },
  },
}));

type CustomSelectProps = {
  items?: string[];
  selectedItem?: string;
  onChange?: any;
  label?: string;
};

const CustomSelect: FunctionComponent<CustomSelectProps> = ({ items, selectedItem, onChange, label }) => {
  const classes = useStyles();

  return (
    <FormControl className={classes.formControl}>
      <Typography>{label}</Typography>
      <Select
        labelId="custom-select-label"
        value={selectedItem}
        onChange={(evt) => onChange(evt.target.value)}
        className={classes.select}
        input={<BootstrapInput />}
      >
        {items &&
          items.map((item) => {
            return (
              <MenuItem key={`custom-select-menu-item-${item}`} value={item}>
                {item}
              </MenuItem>
            );
          })}
      </Select>
    </FormControl>
  );
};
export default CustomSelect;
