import React, { FunctionComponent } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { COLORS, DATE_FORMAT, GRADE_CATEGORIES, PERCENT_FORMAT, PRIMARY_DATE_FORMAT } from '../../../../constants';
import Grade from './Grade';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import InfoIcon from '@material-ui/icons/Info';
import winnerCheckbox from '../../../../assets/images/winner_checkbox.svg';
import InfoPopup from '../../InfoPopup';
import incumbentLabel from './../../../../assets/images/incumbent_label.svg';
import moment from 'moment';

interface StyleProps {
  isExpanded: boolean;
}

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '-5px',
    color: COLORS.darkGray,
    padding: '17px 27px 12px 27px',
  },
  containerActive: {
    boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.09)',
  },
  candidateNameContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    cursor: 'pointer',
  },
  candidateNameInnerContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  candidateName: {
    fontSize: '18px',
    marginRight: '5px',
  },
  lineContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    position: 'relative',
  },
  line: {
    backgroundColor: COLORS.blue,
    height: '6px',
    borderRadius: '11px',
    position: 'relative',
    '&::after': {
      content: '""',
      position: 'absolute',
      right: '-5px',
      bottom: '-2.5px',
      backgroundColor: COLORS.blue,
      borderRadius: '50%',
      width: '10px',
      height: '10px',
    },
  },
  lineText: {
    color: COLORS.blue,
    fontSize: '14px',
    fontWeight: 'bold',
    marginLeft: '10px',
  },
  expandedSection: {
    display: (props: StyleProps) => (props.isExpanded ? 'initial' : 'none'),
  },
  voteShareContainer: {
    paddingTop: '23.5px',
    borderBottom: `1px ${COLORS.gray} solid`,
    paddingBottom: '16.5px',
  },
  voteShare: {
    fontWeight: 'bold',
    fontSize: '16px',
    paddingBottom: '4px',
  },
  candidateProfileContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingTop: '16.5px',
    '& div': {
      display: 'flex',
    },
    '& .img': {
      cursor: 'pointer',
    },
  },
  candidateProfile: {
    textTransform: 'uppercase',
    fontWeight: 'bold',
    fontSize: '16px',
    paddingRight: '9px',
  },
  winnerContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    color: COLORS.blue,
    fontSize: '16px',
    fontWeight: 'bold',
    '& :first-child': {
      marginRight: '5px',
    },
  },
  lastUpdatedText: {
    fontSize: '12px',
    color: COLORS.darkGray,
    fontStyle: 'italic',
    lineHeight: 2.33,
  },
}));

type CandidateProfileProps = {
  row?: any;
  isExpanded?: boolean;
  setExpandedCandidate?: any;
  isUpcoming?: boolean;
};

const CandidateProfile: FunctionComponent<CandidateProfileProps> = ({ row, isExpanded, setExpandedCandidate, isUpcoming }) => {
  const classes = useStyles({ isExpanded: isExpanded } as StyleProps);
  const { width, candidate, chance_of_winning, projected_vote_share, vote_share, win } = row;
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  // Is the candidate an incumbent?
  const isIncumbent: boolean = row.incumbent;

  return (
    <div className={`${classes.container} ${isExpanded ? classes.containerActive : ''}`}>
      <div
        className={classes.candidateNameContainer}
        onClick={() => {
          if (isExpanded) {
            setExpandedCandidate(null);
          } else {
            setExpandedCandidate(candidate);
          }
        }}
      >
        <div className={classes.candidateNameInnerContainer}>
          <div className={classes.candidateName}>{candidate}</div>
          {isIncumbent ? (
            <img src={incumbentLabel} alt="A bubble label representing that the visible candidate is an incumbent candidate." />
          ) : null}
        </div>
        {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </div>
      <div className={classes.lineContainer}>
        <div className={classes.line} style={{ width }} />
        <div className={classes.lineText}>{PERCENT_FORMAT(+chance_of_winning)}</div>
      </div>
      <div className={classes.expandedSection}>
        <div className={classes.voteShareContainer}>
          <div className={classes.voteShare}>Projected Vote Share: {PERCENT_FORMAT(+projected_vote_share)}</div>
          {!isUpcoming && <div className={classes.voteShare}>Actual Vote Share: {PERCENT_FORMAT(+vote_share)}</div>}
          {!!+win && (
            <div className={classes.winnerContainer}>
              <img alt={`${candidate} won the present race and it is identified with a checkbox.`} src={winnerCheckbox} />
              Winner
            </div>
          )}
        </div>
        <div className={classes.candidateProfileContainer}>
          <div className={classes.candidateProfile}>Candidate Profile</div>
          <div className="img" onMouseEnter={handlePopoverOpen} onMouseLeave={handlePopoverClose}>
            <InfoIcon fontSize="small" style={{ fill: COLORS.mediumGray }} />
            <InfoPopup anchorEl={anchorEl} handlePopoverClose={handlePopoverClose} />
          </div>
        </div>
        {GRADE_CATEGORIES.map((grade) => {
          return <Grade key={`grade-for-${grade.id}`} label={grade.label} value={row[grade.id].toLowerCase()} />;
        })}
        <div className={classes.lastUpdatedText}>
          Prediction last updated on <span>{moment(row.updated, PRIMARY_DATE_FORMAT).format(DATE_FORMAT)}</span>
        </div>
      </div>
    </div>
  );
};

export default CandidateProfile;
