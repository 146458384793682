import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { fetchError, fetchSuccess } from './actions';
import { GlobalActionTypes } from './types';
import { callApi } from '../../utils/api';

const API_ENDPOINT = 'https://grass-roots-analytics-data.s3.amazonaws.com';

function* handleFetch() {
  try {
    const res = yield call(callApi, 'get', `${API_ENDPOINT}/democratic-primary-data.json`);
    const states = yield call(callApi, 'get', `${API_ENDPOINT}/states-10m.json`);
    const congressionalDistricts = yield call(callApi, 'get', `${API_ENDPOINT}/congressional-districts-hex.json`);

    if (res.error) {
      yield put(fetchError(res.error));
    } else {
      yield put(
        fetchSuccess({
          data: res,
          states,
          congressionalDistricts,
        }),
      );
    }
  } catch (err) {
    if (err instanceof Error && err.stack) {
      yield put(fetchError(err.stack));
    } else {
      yield put(fetchError('An unknown error occurred.'));
    }
  }
}

// This is our watcher function. We use `take*()` functions to watch Redux for a specific action
// type, and run our saga, for example the `handleFetch()` saga above.
function* watchFetchRequest() {
  yield takeEvery(GlobalActionTypes.FETCH_REQUEST, handleFetch);
}

// We can also use `fork()` here to split our saga into multiple watchers.
function* globalSaga() {
  yield all([fork(watchFetchRequest)]);
}

export default globalSaga;
