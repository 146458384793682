import React, { FunctionComponent } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { COLORS, GRADE_LOOKUP } from '../../../../../constants';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    margin: '10px 0',
    color: COLORS.darkGray,
  },
  label: {
    fontSize: '16px',
    marginRight: '10px',
  },
  img: {
    width: '25px',
    height: '25px',
  },
}));

type GradeProps = {
  label: string;
  value: string;
};

const Grade: FunctionComponent<GradeProps> = ({ label, value }) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.label}>{label}</div>
      <img className={classes.img} alt={`A ${value} grade for ${label} for the chosen candidate.`} src={GRADE_LOOKUP.get(value)} />
    </div>
  );
};

export default Grade;
