import React from 'react';

const CleanData = () => {
  return (
    <ul>
      <li>Cleaning</li>
      <ul>
        <li>
          Input variables from multiple sources and clean them to make them uniform, allowing analysis across our data collection points
        </li>
      </ul>
    </ul>
  );
};

export default CleanData;
