import React, { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles, Typography } from '@material-ui/core';
import { ApplicationState } from '../../redux/store';
import { Dataset } from '../../models/dataset';
import { BUTTON_STYLE, COLORS, STATE_RACE_ID } from '../../constants';
import { useHistory, useParams } from 'react-router-dom';
import { ParamTypes } from '../../models/param-types';

const useStyles = makeStyles(() => ({
  container: {
    textAlign: 'center',
    margin: '20px 0 0 0',
    whiteSpace: 'nowrap',
  },
  button: {
    ...(BUTTON_STYLE as any),
    '&:first-child': {
      borderRadius: '5px 0 0 5px',
    },
  },
  buttonSelected: {
    ...(BUTTON_STYLE as any),
    color: COLORS.white,
    borderColor: COLORS.blue,
    backgroundColor: COLORS.blue,
  },
  text: {
    fontSize: '15px',
    fontWeight: 700,
  },
}));

interface StateProps {
  datasets: Dataset[];
}

const DatasetToggle: FunctionComponent = () => {
  const classes = useStyles();
  const history = useHistory();
  const { datasetId = STATE_RACE_ID } = useParams<ParamTypes>();
  const routeToSelectedDataset = (id: string) => () => {
    return history.push({
      pathname: `/${id}`,
      search: history.location.search,
    });
  };
  const { datasets } = useSelector<ApplicationState, StateProps>((state: ApplicationState) => {
    return {
      datasets: state.global.datasets,
    };
  });

  return (
    <div className={classes.container}>
      {datasets.map((dataset: Dataset) => {
        return (
          <button
            type="button"
            key={`button-for-${dataset.id}`}
            className={dataset.id === datasetId ? classes.buttonSelected : classes.button}
            onClick={routeToSelectedDataset(dataset.id)}
          >
            <Typography className={classes.text}>{dataset.name}</Typography>
          </button>
        );
      })}
    </div>
  );
};
export default DatasetToggle;
