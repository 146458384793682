import React, { FunctionComponent, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { COLORS, STATE_RACE_ID } from '../../../constants';
import { useDispatch } from 'react-redux';
import { setActiveMarker } from '../../../redux/store/global/actions';
import { pointer } from 'd3-selection';

const useStyles = makeStyles(() => ({
  path: {
    strokeWidth: '2px',
    stroke: COLORS.black,
  },
}));

type ActivePathProps = {
  feature?: any;
  path?: any;
  datasetId?: string;
};

const ActivePath: FunctionComponent<ActivePathProps> = ({ feature, path, datasetId }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const activateMarker = useCallback((feature: any) => dispatch(setActiveMarker(feature)), [dispatch]);
  const bbox = path.bounds(feature);
  const x = bbox[0][0];
  const y = bbox[0][1];
  const width = bbox[1][0] - bbox[0][0];
  const height = bbox[1][1] - bbox[0][1];
  const { color, active } = feature;

  const onMouseOver = (evt: any) => {
    const [left, top] = pointer(evt);
    activateMarker({
      ...feature,
      top,
      left,
    });
  };

  const onMouseOut = () => activateMarker(null);

  return (
    <path
      transform={`scale(${datasetId === STATE_RACE_ID ? 1.15 : 1.5})`}
      className={classes.path}
      d={path(feature)}
      onMouseOver={(evt) => active && onMouseOver(evt)}
      onMouseOut={onMouseOut}
      style={{
        cursor: active ? 'pointer' : 'default',
        fill: color,
        transformOrigin: `${x + width / 2}px ${y + height / 2}px`,
      }}
    />
  );
};

export default ActivePath;
