import { format } from 'd3-format';
import aGradeImage from './assets/images/grades/a-grade.png';
import bGradeImage from './assets/images/grades/b-grade.png';
import cGradeImage from './assets/images/grades/c-grade.png';
import dGradeImage from './assets/images/grades/d-grade.png';
import fGradeImage from './assets/images/grades/f-grade.png';
import { geoAlbersUsa, geoIdentity } from 'd3-geo';
import { breakApartStateDist, congressionalDistrictIdAccessor, ensure, isStateRace } from './utils';
import { StateMeta } from './models/state-meta';
import { RaceCompetition } from './models/race-competition';

export const BASE_ROUTE = '';

const _STATE_RACE_ID = 'state-races';
export const STATE_RACE_ID = _STATE_RACE_ID;
export const DATASETS = [
  {
    id: _STATE_RACE_ID,
    name: 'State-Wide Races',
    topology: {},
    topojsonObject: 'states',
    topojsonAccessor: 'states',
    features: [],
    projection: geoAlbersUsa(),
    labelAccessor: (d: any) => (d ? `${d?.state} ${d?.raceLabel}` : ''),
    idAccessor: (d: any) => d.id,
    getMatchingRows: (xs: any[], id: string) => {
      const stateObject = ensure(ALL_STATE_META.find((d) => d.fips === id));
      return xs && xs.filter((row: any) => isStateRace(row) && row.state.toLowerCase() === stateObject.abbr.toLowerCase());
    },
  },
  {
    id: 'house-races',
    name: 'House Races',
    topology: {},
    topojsonObject: 'congressionalDistricts',
    topojsonAccessor: 'HexCDv21',
    features: [],
    projection: geoIdentity().reflectY(true),
    labelAccessor: (d: any) => (d ? `${d?.state}-${d?.district}` : ''),
    idAccessor: congressionalDistrictIdAccessor,
    getMatchingRows: (xs: any, id: string) => xs && xs.filter((row: any) => breakApartStateDist(row) === id),
  },
];

export enum COLORS {
  blue = '#1c71c6',
  darkGray = '#3c4545',
  white = '#fff',
  grayLighter = '#f2f2f2',
  gray = '#f1f4f4',
  mediumGray = '#ccd3d3',
  black = '#000',
}

export enum RACES {
  House = 'House',
  Senate = 'Senate',
  Mayor = 'Mayoral',
  Governor = 'Governor',
  LieutenantGovernor = 'Lieutenant Governor',
}

export const OFFICE_CATEGORY_LOOKUP = (office: string, stateDist: string): string => {
  const match = new Map([
    ['house', RACES.House],
    ['senate', RACES.Senate],
    ['citywide', RACES.Mayor],
  ]);
  if (match.has(office)) {
    return match.get(office) as string;
  } else {
    const race = stateDist.split('_');
    return race[race.length - 1].toUpperCase() === 'G' ? RACES.Governor : RACES.LieutenantGovernor;
  }
};

export const GRADE_CATEGORIES = [
  { id: 'grade_fundraising', label: 'Fundraising' },
  { id: 'grade_demographic', label: 'Demographic match' },
  { id: 'grade_namerecog', label: 'Name recognition' },
  { id: 'grade_orgsupport', label: 'Organizational support' },
];

export const GRADE_LOOKUP = new Map([
  ['a', aGradeImage],
  ['b', bGradeImage],
  ['c', cGradeImage],
  ['d', dGradeImage],
  ['f', fGradeImage],
]);

export const PRIMARY_DATE_FORMAT = 'DDMMMYYYY';
export const PRIMARY_DATE_FORMAT_READABLE = 'DD.MMM.YYYY';
export const DATE_FORMAT = 'M/D/YYYY';
export const LONG_DATE_FORMAT = 'MMMM D, YYYY';
export const PERCENT_FORMAT = format(',.0%');
export const DURATION = 1000;

export const COOKIE_KEY = 'primaryCastEmail';

export const ALL_STATE_META: StateMeta[] = [
  {
    row: 6,
    col: 7,
    abbr: 'AL',
    name: 'Alabama',
    size: 'tall',
    fips: '01',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    topCD: 10,
    leftCD: 0,
    rightCD: 5,
    bottomCD: 0,
    positionCD: 'bottom-right',
  },
  {
    row: 0,
    col: 0,
    abbr: 'AK',
    name: 'Alaska',
    size: 'medium',
    fips: '02',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    topCD: 0,
    leftCD: 0,
    rightCD: 0,
    bottomCD: 0,
    positionCD: 'bottom-right',
  },
  {
    row: 5,
    col: 2,
    abbr: 'AZ',
    name: 'Arizona',
    size: 'tall',
    fips: '04',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    topCD: 0,
    leftCD: 0,
    rightCD: 0,
    bottomCD: 0,
    positionCD: 'bottom-right',
  },
  {
    row: 5,
    col: 5,
    abbr: 'AR',
    name: 'Arkansas',
    size: 'medium',
    fips: '05',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    topCD: 0,
    leftCD: 0,
    rightCD: 5,
    bottomCD: 0,
    positionCD: 'bottom-right',
  },
  {
    row: 4,
    col: 1,
    abbr: 'CA',
    name: 'California',
    size: 'tall',
    fips: '06',
    top: 0,
    left: 15,
    right: 0,
    bottom: 5,
    topCD: 0,
    leftCD: 90,
    rightCD: 0,
    bottomCD: 50,
    positionCD: 'top-right',
  },
  {
    row: 4,
    col: 3,
    abbr: 'CO',
    name: 'Colorado',
    size: 'medium',
    fips: '08',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    topCD: 0,
    leftCD: 0,
    rightCD: 0,
    bottomCD: 0,
    positionCD: 'bottom-right',
  },
  {
    row: 3,
    col: 10,
    abbr: 'CT',
    name: 'Connecticut',
    size: 'medium',
    fips: '09',
    top: 0,
    left: 0,
    right: 0,
    bottom: 2,
    topCD: 15,
    leftCD: 10,
    rightCD: 0,
    bottomCD: 0,
    positionCD: 'bottom-right',
  },
  {
    row: 4,
    col: 10,
    abbr: 'DE',
    name: 'Delaware',
    size: 'tall',
    fips: '10',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    topCD: 0,
    leftCD: 0,
    rightCD: 0,
    bottomCD: 0,
    positionCD: 'bottom-right',
  },
  {
    row: 7,
    col: 9,
    abbr: 'FL',
    name: 'Florida',
    size: 'tall',
    fips: '12',
    top: 0,
    left: 0,
    right: 10,
    bottom: 8,
    topCD: 0,
    leftCD: 0,
    rightCD: 0,
    bottomCD: 0,
    positionCD: 'bottom-right',
  },
  {
    row: 6,
    col: 8,
    abbr: 'GA',
    name: 'Georgia',
    size: 'medium',
    fips: '13',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    topCD: 15,
    leftCD: 0,
    rightCD: 5,
    bottomCD: 0,
    positionCD: 'bottom-right',
  },
  {
    row: 7,
    col: 0,
    abbr: 'HI',
    name: 'Hawaii',
    size: 'medium',
    fips: '15',
    top: 0,
    left: 0,
    right: 7,
    bottom: 12,
    topCD: 0,
    leftCD: 0,
    rightCD: 0,
    bottomCD: 0,
    positionCD: 'bottom-right',
  },
  {
    row: 2,
    col: 2,
    abbr: 'ID',
    name: 'Idaho',
    size: 'tall',
    fips: '16',
    top: 0,
    left: 0,
    right: 0,
    bottom: 8,
    topCD: 0,
    leftCD: 5,
    rightCD: 0,
    bottomCD: 30,
    positionCD: 'top-right',
  },
  {
    row: 2,
    col: 6,
    abbr: 'IL',
    name: 'Illinois',
    size: 'tall',
    fips: '17',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    topCD: 0,
    leftCD: 5,
    rightCD: 0,
    bottomCD: 0,
    positionCD: 'bottom-right',
  },
  {
    row: 3,
    col: 6,
    abbr: 'IN',
    name: 'Indiana',
    size: 'tall',
    fips: '18',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    topCD: 10,
    leftCD: 15,
    rightCD: 0,
    bottomCD: 0,
    positionCD: 'bottom-right',
  },
  {
    row: 3,
    col: 5,
    abbr: 'IA',
    name: 'Iowa',
    size: 'medium',
    fips: '19',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    topCD: 0,
    leftCD: 0,
    rightCD: 0,
    bottomCD: 0,
    positionCD: 'bottom-right',
  },
  {
    row: 5,
    col: 4,
    abbr: 'KS',
    name: 'Kansas',
    size: 'short',
    fips: '20',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    topCD: 0,
    leftCD: 0,
    rightCD: 5,
    bottomCD: 0,
    positionCD: 'bottom-right',
  },
  {
    row: 4,
    col: 6,
    abbr: 'KY',
    name: 'Kentucky',
    size: 'short',
    fips: '21',
    top: 3,
    left: 0,
    right: 8,
    bottom: 0,
    topCD: 5,
    leftCD: 0,
    rightCD: 0,
    bottomCD: 0,
    positionCD: 'bottom-right',
  },
  {
    row: 6,
    col: 5,
    abbr: 'LA',
    name: 'Louisiana',
    size: 'medium',
    fips: '22',
    top: 0,
    left: 10,
    right: 0,
    bottom: 0,
    topCD: 0,
    leftCD: 0,
    rightCD: 0,
    bottomCD: 15,
    positionCD: 'bottom-right',
  },
  {
    row: 0,
    col: 11,
    abbr: 'ME',
    name: 'Maine',
    size: 'tall',
    fips: '23',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    topCD: 5,
    leftCD: 5,
    rightCD: 0,
    bottomCD: 0,
    positionCD: 'bottom-right',
  },
  {
    row: 4,
    col: 9,
    abbr: 'MD',
    name: 'Maryland',
    size: 'short',
    fips: '24',
    top: 3,
    left: 2,
    right: 0,
    bottom: 0,
    topCD: 0,
    leftCD: 5,
    rightCD: 0,
    bottomCD: 0,
    positionCD: 'bottom-right',
  },
  {
    row: 2,
    col: 11,
    abbr: 'MA',
    name: 'Massachusetts',
    size: 'short',
    fips: '25',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    topCD: 0,
    leftCD: 10,
    rightCD: 0,
    bottomCD: 5,
    positionCD: 'bottom-right',
  },
  {
    row: 2,
    col: 8,
    abbr: 'MI',
    name: 'Michigan',
    size: 'tall',
    fips: '26',
    top: 0,
    left: 0,
    right: 10,
    bottom: 25,
    topCD: 0,
    leftCD: 10,
    rightCD: 0,
    bottomCD: 70,
    positionCD: 'top-right',
  },
  {
    row: 2,
    col: 5,
    abbr: 'MN',
    name: 'Minnesota',
    size: 'tall',
    fips: '27',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    topCD: 0,
    leftCD: 15,
    rightCD: 0,
    bottomCD: 10,
    positionCD: 'top-right',
  },
  {
    row: 6,
    col: 6,
    abbr: 'MS',
    name: 'Mississippi',
    size: 'tall',
    fips: '28',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    topCD: 0,
    leftCD: 0,
    rightCD: 10,
    bottomCD: 10,
    positionCD: 'bottom-right',
  },
  {
    row: 4,
    col: 5,
    abbr: 'MO',
    name: 'Missouri',
    size: 'medium',
    fips: '29',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    topCD: 0,
    leftCD: 5,
    rightCD: 0,
    bottomCD: 0,
    positionCD: 'bottom-right',
  },
  {
    row: 2,
    col: 3,
    abbr: 'MT',
    name: 'Montana',
    size: 'medium',
    fips: '30',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    topCD: 0,
    leftCD: 0,
    rightCD: 0,
    bottomCD: 0,
    positionCD: 'bottom-right',
  },
  {
    row: 4,
    col: 4,
    abbr: 'NE',
    name: 'Nebraska',
    size: 'short',
    fips: '31',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    topCD: 0,
    leftCD: 0,
    rightCD: 5,
    bottomCD: 0,
    positionCD: 'bottom-right',
  },
  {
    row: 3,
    col: 2,
    abbr: 'NV',
    name: 'Nevada',
    size: 'tall',
    fips: '32',
    top: 3,
    left: 3,
    right: 0,
    bottom: 0,
    topCD: 0,
    leftCD: 5,
    rightCD: 0,
    bottomCD: 0,
    positionCD: 'bottom-right',
  },
  {
    row: 1,
    col: 11,
    abbr: 'NH',
    name: 'New Hampshire',
    size: 'tall',
    fips: '33',
    top: 0,
    left: 0,
    right: 0,
    bottom: 8,
    topCD: 0,
    leftCD: 20,
    rightCD: 0,
    bottomCD: 0,
    positionCD: 'top-right',
  },
  {
    row: 3,
    col: 9,
    abbr: 'NJ',
    name: 'New Jersey',
    size: 'tall',
    fips: '34',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    topCD: 10,
    leftCD: 5,
    rightCD: 0,
    bottomCD: 0,
    positionCD: 'bottom-right',
  },
  {
    row: 5,
    col: 3,
    abbr: 'NM',
    name: 'New Mexico',
    size: 'medium',
    fips: '35',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    topCD: 0,
    leftCD: 0,
    rightCD: 0,
    bottomCD: 0,
    positionCD: 'bottom-right',
  },
  {
    row: 2,
    col: 9,
    abbr: 'NY',
    name: 'New York',
    size: 'medium',
    fips: '36',
    top: 0,
    left: 0,
    right: 4,
    bottom: 0,
    topCD: 0,
    leftCD: 15,
    rightCD: 0,
    bottomCD: 0,
    positionCD: 'bottom-right',
  },
  {
    row: 5,
    col: 7,
    abbr: 'NC',
    name: 'North Carolina',
    size: 'short',
    fips: '37',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    topCD: 10,
    leftCD: 15,
    rightCD: 0,
    bottomCD: 0,
    positionCD: 'bottom-right',
  },
  {
    row: 2,
    col: 4,
    abbr: 'ND',
    name: 'North Dakota',
    size: 'short',
    fips: '38',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    topCD: 0,
    leftCD: 0,
    rightCD: 0,
    bottomCD: 0,
    positionCD: 'bottom-right',
  },
  {
    row: 3,
    col: 7,
    abbr: 'OH',
    name: 'Ohio',
    size: 'tall',
    fips: '39',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    topCD: 0,
    leftCD: 0,
    rightCD: 0,
    bottomCD: 5,
    positionCD: 'top-right',
  },
  {
    row: 6,
    col: 4,
    abbr: 'OK',
    name: 'Oklahoma',
    size: 'short',
    fips: '40',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    topCD: 0,
    leftCD: 0,
    rightCD: 0,
    bottomCD: 0,
    positionCD: 'bottom-right',
  },
  {
    row: 3,
    col: 1,
    abbr: 'OR',
    name: 'Oregon',
    size: 'tall',
    fips: '41',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    topCD: 0,
    leftCD: 0,
    rightCD: 0,
    bottomCD: 0,
    positionCD: 'bottom-right',
  },
  {
    row: 3,
    col: 8,
    abbr: 'PA',
    name: 'Pennsylvania',
    size: 'medium',
    fips: '42',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    topCD: 0,
    leftCD: 10,
    rightCD: 0,
    bottomCD: 0,
    positionCD: 'top-right',
  },
  {
    row: 7,
    col: 11,
    abbr: 'PR',
    name: 'Puerto Rico',
    size: 'medium',
    fips: '43',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    topCD: 0,
    leftCD: 0,
    rightCD: 0,
    bottomCD: 0,
    positionCD: 'bottom-right',
  },
  {
    row: 2,
    col: 10,
    abbr: 'RI',
    name: 'Rhode Island',
    size: 'medium',
    fips: '44',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    topCD: 0,
    leftCD: 0,
    rightCD: 0,
    bottomCD: 0,
    positionCD: 'bottom-right',
  },
  {
    row: 5,
    col: 8,
    abbr: 'SC',
    name: 'South Carolina',
    size: 'short',
    fips: '45',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    topCD: 5,
    leftCD: 10,
    rightCD: 0,
    bottomCD: 0,
    positionCD: 'bottom-right',
  },
  {
    row: 3,
    col: 4,
    abbr: 'SD',
    name: 'South Dakota',
    size: 'medium',
    fips: '46',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    topCD: 0,
    leftCD: 0,
    rightCD: 0,
    bottomCD: 0,
    positionCD: 'bottom-right',
  },
  {
    row: 5,
    col: 6,
    abbr: 'TN',
    name: 'Tennessee',
    size: 'short',
    fips: '47',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    topCD: 15,
    leftCD: 30,
    rightCD: 0,
    bottomCD: 0,
    positionCD: 'bottom-right',
  },
  {
    row: 7,
    col: 4,
    abbr: 'TX',
    name: 'Texas',
    size: 'tall',
    fips: '48',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    topCD: 30,
    leftCD: 65,
    rightCD: 0,
    bottomCD: 0,
    positionCD: 'bottom-right',
  },
  {
    row: 4,
    col: 2,
    abbr: 'UT',
    name: 'Utah',
    size: 'tall',
    fips: '49',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    topCD: 0,
    leftCD: 0,
    rightCD: 0,
    bottomCD: 0,
    positionCD: 'bottom-right',
  },
  {
    row: 1,
    col: 10,
    abbr: 'VT',
    name: 'Vermont',
    size: 'tall',
    fips: '50',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    topCD: 0,
    leftCD: 0,
    rightCD: 0,
    bottomCD: 0,
    positionCD: 'bottom-right',
  },
  {
    row: 4,
    col: 8,
    abbr: 'VA',
    name: 'Virginia',
    size: 'medium',
    fips: '51',
    top: 0,
    left: 0,
    right: 7,
    bottom: 0,
    topCD: 30,
    leftCD: 0,
    rightCD: 0,
    bottomCD: 0,
    positionCD: 'bottom-right',
  },
  {
    row: 2,
    col: 1,
    abbr: 'WA',
    name: 'Washington',
    size: 'medium',
    fips: '53',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    topCD: 0,
    leftCD: 0,
    rightCD: 0,
    bottomCD: 0,
    positionCD: 'bottom-right',
  },
  {
    row: 4,
    col: 7,
    abbr: 'WV',
    name: 'West Virginia',
    size: 'tall',
    fips: '54',
    top: 0,
    left: 0,
    right: 5,
    bottom: 7,
    topCD: 10,
    leftCD: 25,
    rightCD: 0,
    bottomCD: 0,
    positionCD: 'bottom-right',
  },
  {
    row: 2,
    col: 7,
    abbr: 'WI',
    name: 'Wisconsin',
    size: 'tall',
    fips: '55',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    topCD: 10,
    leftCD: 0,
    rightCD: 0,
    bottomCD: 0,
    positionCD: 'bottom-right',
  },
  {
    row: 3,
    col: 3,
    abbr: 'WY',
    name: 'Wyoming',
    size: 'medium',
    fips: '56',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    topCD: 0,
    leftCD: 0,
    rightCD: 0,
    bottomCD: 0,
    positionCD: 'bottom-right',
  },
];

export const COMPETITIONS: RaceCompetition[] = [
  {
    color: '#dce4e5',
    label: 'Uncontested',
    id: 'uncontested',
    textColor: COLORS.darkGray,
  },
  {
    color: '#bfdce2',
    label: 'Not Competitive',
    id: 'no contest',
    textColor: COLORS.darkGray,
  },
  {
    color: '#58c6e2',
    label: 'Competitive',
    id: 'contested',
    textColor: COLORS.darkGray,
  },
  {
    color: COLORS.blue,
    label: 'Very Competitive',
    id: 'very contested',
    textColor: COLORS.white,
  },
];

export const BUTTON_STYLE = {
  fontSize: '14px',
  width: '175px',
  fontWeight: 'bold',
  backgroundColor: 'transparent',
  border: `1px solid ${COLORS.blue}`,
  padding: '6.5px 8px',
  color: COLORS.blue,
  cursor: 'pointer',
  textTransform: 'uppercase',
  transition: '0.2s background-color, 0.2s border-color, 0.2s color',
  '&:hover': {
    backgroundColor: COLORS.grayLighter,
    color: COLORS.blue,
  },
  '&:first-child': {
    borderRadius: '5px 0 0 5px',
  },
  '&:last-child': {
    borderRadius: '0 5px 5px 0',
  },
  '&:focus': {
    outline: 'none',
  },
};
