import React, { cloneElement, FunctionComponent } from 'react';
import { useDimensions } from '../../utils';
import { Dataset } from '../../models/dataset';
import { Margin } from '../../models/margin';

type BaseChartProps = {
  className: string;
  margin?: Margin;
  children: any;
  dataset: Dataset;
};

const BaseChart: FunctionComponent<BaseChartProps> = ({
  className,
  dataset,
  children,
  margin = {
    left: 20,
    right: 20,
    top: 0,
    bottom: 20,
  },
}) => {
  const [visualizationRef, { width, height, isResized }] = useDimensions({
    width: 100,
    height: 50,
    isResized: false,
  });

  const innerWidth = width - margin.left - margin.right;
  const innerHeight = height - margin.top - margin.bottom;

  return (
    <svg width={width} height={height} ref={visualizationRef} className={className}>
      {isResized &&
        cloneElement(children, {
          innerWidth,
          innerHeight,
          dataset,
          margin,
        })}
    </svg>
  );
};

export default BaseChart;
