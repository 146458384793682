import React, { FunctionComponent } from 'react';
import { FormControl, makeStyles, FormControlLabel, FormGroup, Switch, withStyles } from '@material-ui/core';
import { COLORS } from '../../constants';
import { useHistory } from 'react-router-dom';

const CustomSwitch = withStyles({
  switchBase: {
    color: COLORS.blue,
    '&$checked': {
      color: COLORS.blue,
    },
    '&$checked + $track': {
      backgroundColor: COLORS.blue,
    },
  },
  checked: {},
  track: {},
})(Switch);

const useStyles = makeStyles((theme) => ({
  container: {
    textAlign: 'center',
    margin: '10px 0',
    position: 'absolute',
    right: '30px',
    top: '8px',
    [theme.breakpoints.down(1380)]: {
      position: 'static',
      right: 'auto',
    },
  },
  text: {
    fontSize: '16px',
    color: COLORS.darkGray,
  },
}));

const UpcomingRaces: FunctionComponent = () => {
  const classes = useStyles();
  const history = useHistory();
  const params = new URLSearchParams(history.location.search);
  const routeToUpcomingRace = () => () =>
    history.push({
      pathname: history.location.pathname,
      search: `?upcomingRace=${params.get('upcomingRace') !== 'true'}`,
    });

  return (
    <div className={classes.container}>
      <FormControl component="fieldset">
        <FormGroup aria-label="position" row>
          <FormControlLabel
            className={classes.text}
            value="start"
            control={
              <CustomSwitch onChange={routeToUpcomingRace()} checked={params.get('upcomingRace') === 'true'} name="upcoming-races-switch" />
            }
            label="Only show upcoming races"
            labelPlacement="start"
          />
        </FormGroup>
      </FormControl>
    </div>
  );
};
export default UpcomingRaces;
