import { makeStyles } from '@material-ui/core/styles';
import { COLORS } from '../../../constants';
import React, { FunctionComponent } from 'react';
import AboutLinks from '../../AboutLinks';

const useStyles = makeStyles((theme) => ({
  about: {
    borderTop: `3px solid ${COLORS.blue}`,
    padding: '25px 27px',
    background: COLORS.grayLighter,
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
    '& a': {
      color: COLORS.blue,
    },
  },
}));

type AboutProps = {
  setOpen: any;
};

const About: FunctionComponent<AboutProps> = ({ setOpen }) => {
  const classes = useStyles();

  return (
    <div className={classes.about}>
      <AboutLinks setOpen={setOpen} />
    </div>
  );
};

export default About;
