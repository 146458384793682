import React, { FunctionComponent, useState } from 'react';
import { Button, Modal } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { COLORS, COOKIE_KEY } from '../../constants';
//import CloseIcon from '@material-ui/icons/Close';
import InputBase from '@material-ui/core/InputBase';
import * as EmailValidator from 'email-validator';
import { postData } from '../../utils';
import CircularProgress from '@material-ui/core/CircularProgress';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  close: {
    position: 'absolute',
    top: 0,
    right: 0,
    width: '60px',
    height: '60px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: COLORS.blue,
    cursor: 'pointer',
    '&:hover': {
      background: COLORS.darkGray,
    },
  },
  closeIcon: {
    color: COLORS.white,
    fontSize: '50px',
  },
  paper: {
    position: 'relative',
    maxWidth: '713px',
    textAlign: 'left',
    backgroundColor: COLORS.white,
    boxShadow: '0 0 40px rgba(0, 0, 0, 0.25)',
    outline: 0,
    borderRadius: '10px',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '90%',
    },
    '& h2': {
      fontSize: '32px',
      color: COLORS.blue,
      fontWeight: 'bold',
      marginBottom: '15px',
      lineHeight: 1.33,
    },
  },
  submitSection: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    '& label': {
      fontSize: '17px',
      lineHeight: '1.53',
      color: COLORS.darkGray,
      flex: '0 0 100%',
    },
    flexWrap: 'wrap',
  },
  form: {
    '& .text-input': {
      marginRight: theme.spacing(1),
      width: '503px',
      height: '50px',
      backgroundColor: '#f1f4f4',
      margin: `${theme.spacing(2)}px 0`,
      padding: theme.spacing(2),
      borderRadius: '5px',
      flex: '0 0 calc(100% - 130px)',
      [theme.breakpoints.down('sm')]: {
        flex: '0 0 100%',
      },
    },
  },
  button: {
    height: '50px',
    backgroundColor: '#58c6e2',
    color: COLORS.white,
    fontSize: '26px',
    borderRadius: '5px',
    textTransform: 'uppercase',
    flex: '0 0 118px',
  },
  content: {
    maxHeight: '600px',
    overflowY: 'auto',
    padding: '15px 70px 30px 50px',
    [theme.breakpoints.down('sm')]: {
      maxHeight: '100%',
      height: '100%',
    },
  },
  disclaimer: {
    color: COLORS.darkGray,
    fontStyle: 'italic',
    fontSize: '14px',
    marginTop: '10px',
    lineHeight: '1.29',
  },
  byPass: {
    color: COLORS.darkGray,
    fontStyle: 'italic',
    fontSize: '10px',
    marginTop: '10px',
    lineHeight: '1.29',
  },
  click: {
    textDecoration: 'underline',
    color: COLORS.blue,
    cursor: 'pointer',
  },
  loader: {
    position: 'absolute',
    top: '50%',
    right: '50%',
  },
  error: {
    color: '#D64200',
    marginTop: '-5px',
  },
}));

type EmailCollectionModalProps = {
  open: boolean;
  setOpen: any;
};

const EmailCollectionModal: FunctionComponent<EmailCollectionModalProps> = ({ open, setOpen }) => {
  const classes = useStyles();
  const [error, setError] = useState(false);
  const [emailAddress, setEmailAddress] = useState('');
  const [loading, setLoading] = useState(false);

  const onChange = (evt: any) => {
    setEmailAddress(evt.target.value);
  };

  const onSubmit = async (evt: any) => {
    evt.preventDefault();
    const email = emailAddress.trim();
    const isValidEmail: boolean = EmailValidator.validate(email);
    if (!isValidEmail) {
      setError(true);
    } else {
      setLoading(true);
      postData('https://dd7wcdr28f.execute-api.us-east-1.amazonaws.com/dev/upload', { email })
        .then((data) => {
          if (data.success) {
            cookies.set(COOKIE_KEY, 'true', { path: '/' });
            setOpen(false);
          }
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
          setOpen(false);
        });
    }
  };

  return (
    <Modal
      disableBackdropClick
      open={open}
      onClose={() => setOpen(false)}
      className={classes.modal}
      aria-labelledby="primary-cast-email-validation"
      aria-describedby="Stay up to date on latest predictions and candidate additions for PrimaryCast"
    >
      <div className={classes.paper}>
        {/* <div className={classes.close}>
          <CloseIcon className={classes.closeIcon} onClick={() => setOpen(false)} />
        </div> */}
        <div className={classes.content}>
          <h2>Stay up to date on latest predictions and candidate additions</h2>
          <form className={classes.form} noValidate autoComplete="off" onSubmit={onSubmit}>
            <div className={classes.submitSection}>
              <label htmlFor="email-input">Enter a valid email address:</label>
              <InputBase
                onChange={onChange}
                id="email-input"
                error={error}
                defaultValue={emailAddress}
                className="text-input"
                placeholder="Enter email address"
                inputProps={{ 'aria-label': 'enter email address for PrimaryCast' }}
              />
              <Button type="submit" variant="contained" className={classes.button}>
                Submit
              </Button>
            </div>
            {error && <div className={classes.error}>Invalid email address</div>}
          </form>
          <div className={classes.disclaimer}>
            By inputting your email, you are subscribing to the PrimaryCast updates along with our newsletter. You can unsubscribe at any
            time. This website uses cookies to enhance the user experience.
          </div>
          <div className={classes.byPass}>
            To proceed without entering an email address, click{' '}
            <span className={classes.click} onClick={() => setOpen(false)}>
              here.
            </span>
          </div>
          {loading && <CircularProgress className={classes.loader} />}
        </div>
      </div>
    </Modal>
  );
};

export default EmailCollectionModal;
