import { Store, createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createBrowserHistory } from 'history';
import { ApplicationState, createRootReducer, rootSaga } from './redux/store';
import { routerMiddleware } from 'react-router-redux';

export const history = createBrowserHistory();

export default function configureStore(initialState: ApplicationState): Store<ApplicationState> {
  const composeEnhancers = composeWithDevTools({});
  const sagaMiddleware = createSagaMiddleware();
  const middleware = [sagaMiddleware, routerMiddleware(history)];

  const store = createStore(createRootReducer(), initialState, composeEnhancers(applyMiddleware(...middleware)));

  sagaMiddleware.run(rootSaga);
  return store;
}
