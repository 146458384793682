import React, { FunctionComponent } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { COLORS, STATE_RACE_ID } from '../../../constants';
import Race from './Race';
import { descending } from 'd3-array';
import { isHouseRace, isStateRace, isUpcomingRace } from '../../../utils';
import About from '../About';
import DatasetToggle from '../../DatasetToggle';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../../redux/store';
import { Dataset } from '../../../models/dataset';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      paddingBottom: 0,
    },
  },
  text: {
    fontSize: '20px',
    color: COLORS.darkGray,
    '& span': {
      color: COLORS.blue,
      fontWeight: 'bold',
    },
    borderBottom: `1px ${COLORS.mediumGray} solid`,
    margin: '0 27px 20px 27px',
    padding: '10px 0',
    ' & div': {
      display: 'none',
      marginLeft: '-15px',
      [theme.breakpoints.down('sm')]: {
        display: 'inherit',
      },
    },
  },
  subTitle: {
    fontSize: '16px',
    color: COLORS.darkGray,
    fontStyle: 'italic',
    margin: '0 27px 20px 27px',
  },
  races: {
    overflowY: 'auto',
    paddingBottom: '15px',
  },
}));

type SidebarProps = {
  selectedDataset: Dataset;
  setOpen: any;
};

interface StateProps {
  data: any[];
}

const Sidebar: FunctionComponent<SidebarProps> = ({ selectedDataset, setOpen }) => {
  const classes = useStyles();

  const { data } = useSelector<ApplicationState, StateProps>((state: ApplicationState) => {
    return {
      data: state.global.data,
    };
  });

  const xs = data
    .filter((d) => (selectedDataset.id === STATE_RACE_ID ? isStateRace(d) : isHouseRace(d)))
    .filter(isUpcomingRace)
    .sort((a, b) => descending(a.dt, b.dt));

  return (
    <div className={classes.container}>
      <div className={classes.text}>
        <p>
          Welcome to PrimaryCast's Democratic primary prediction map. This model predicts the winner of upcoming Democratic primaries, while
          providing projected vote share and details on each candidate's profile.
        </p>
        <p>
          Click on a <span>State</span> or a <span>District</span> to see who is running, and what their chances are of winning.
        </p>
        <DatasetToggle />
      </div>
      <div className={classes.subTitle}>Upcoming races:</div>
      <div className={classes.races}>
        {xs.map((d, i) => {
          return <Race key={`row-for-${i}`} row={d} />;
        })}
      </div>
      <About setOpen={setOpen} />
    </div>
  );
};

export default Sidebar;
