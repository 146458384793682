import React, { FunctionComponent } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Sidebar from './Sidebar';
import SidebarDetailed from './SidebarDetailed';
import { COLORS, STATE_RACE_ID } from '../../constants';
import { Dataset } from '../../models/dataset';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../redux/store';
import { ParamTypes } from '../../models/param-types';
import { isStateRace } from '../../utils';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '398px',
    height: '100%',
    position: 'absolute',
    top: 0,
    right: 0,
    boxShadow: '0 0 30px 0 rgba(0, 0, 0, 0.09)',
    backgroundColor: COLORS.white,
    lineHeight: '1.4',
    color: COLORS.darkGray,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      boxShadow: 'none',
      height: 'auto',
    },
  },
}));

type SidebarProps = {
  selectedDataset: Dataset;
  setOpen: any;
};

interface StateProps {
  data: any;
}

const SidebarContainer: FunctionComponent<SidebarProps> = ({ selectedDataset, setOpen }) => {
  const classes = useStyles();
  const { data } = useSelector<ApplicationState, StateProps>((state: ApplicationState) => {
    return {
      data: state.global.data,
    };
  });
  const { activeRaceId = null, datasetId = STATE_RACE_ID } = useParams<ParamTypes>();
  const activeRace = data.find((row: any) => {
    return row.key === activeRaceId;
  });

  // If there is an active race selected, find all the "keys" that match the state in question,
  // also taking in account if states are being shown
  let matchingRaces = [];
  if (activeRace) {
    if (datasetId === STATE_RACE_ID) {
      matchingRaces = data.filter((d: any) => {
        return d.key.slice(0, 2).toLowerCase() === activeRace.key.slice(0, 2).toLowerCase() && isStateRace(d);
      });
    } else {
      matchingRaces = [];
    }
  }

  return (
    <div className={classes.container}>
      {activeRace ? (
        <SidebarDetailed setOpen={setOpen} row={activeRace} matchingRaces={matchingRaces} />
      ) : (
        <Sidebar setOpen={setOpen} selectedDataset={selectedDataset} />
      )}
    </div>
  );
};

export default SidebarContainer;
