import React, { FunctionComponent } from 'react';
import Bubble from '../../Bubble';
import { DATE_FORMAT, LONG_DATE_FORMAT, PRIMARY_DATE_FORMAT_READABLE, STATE_RACE_ID } from '../../../../constants';
import CustomSelect from '../../CustomSelect';
import moment from 'moment';
import { useHistory, useParams } from 'react-router-dom';
import { ParamTypes } from '../../../../models/param-types';

type CDTopContainerProps = {
  row: any;
  candidate: any;
  classes: any;
};

const CDTopContainer: FunctionComponent<CDTopContainerProps> = ({ row, candidate, classes }) => {
  const { datasetId = STATE_RACE_ID } = useParams<ParamTypes>();
  const history = useHistory();

  // Is it a run-off?
  const isRunOff: boolean = candidate.runoff;

  // Handle select dropdown label
  const label = 'Select another primary for this district';

  // Get dropdown options
  const options: string[] = Array.from(new Set(row.values.map((d: any) => d.dt.format(DATE_FORMAT))));

  // Handle dropdown on change
  const onChange = (id: string) => {
    return history.push({
      pathname: `/${datasetId}/${candidate.state_dist}/${moment(id, DATE_FORMAT).format(PRIMARY_DATE_FORMAT_READABLE)}`,
      search: history.location.search,
    });
  };

  return (
    <div className={classes.topContainer}>
      <div className={classes.title}>{candidate.stateName}</div>
      <div className={classes.subTitle}>{candidate.raceLabel}</div>
      <Bubble row={candidate} />
      <div className={classes.date}>
        Primary Date {isRunOff ? '(RUNOFF)' : ''} : <span>{candidate.dt.format(LONG_DATE_FORMAT)}</span>
      </div>
      <div className={classes.selectDropdown}>
        {options.length > 1 && (
          <CustomSelect label={label} selectedItem={candidate.dt.format(DATE_FORMAT)} items={options} onChange={onChange} />
        )}
      </div>
      <div className={classes.chanceOfWinning}>Chance of Winning (click to expand)</div>
    </div>
  );
};

export default CDTopContainer;
