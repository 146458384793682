import React, { FunctionComponent } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import { COMPETITIONS } from '../../../constants';

const useStyles = makeStyles((theme) => ({
  bubble: {
    textTransform: 'uppercase',
    marginRight: theme.spacing(1),
    fontSize: '15px',
  },
}));

type BubbleProps = {
  row?: any;
};

const Bubble: FunctionComponent<BubbleProps> = ({ row }) => {
  const classes = useStyles();

  const matchingCompetition: any = COMPETITIONS.find((d) => d.id === row.uncontested);
  const { color, textColor, label } = matchingCompetition;

  return <Chip className={classes.bubble} label={label} style={{ backgroundColor: color, color: textColor }} />;
};

export default Bubble;
