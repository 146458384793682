import React, { FunctionComponent } from 'react';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  popover: {
    pointerEvents: 'none',
  },
  paper: {
    padding: theme.spacing(1),
    maxWidth: '500px',
  },
}));

type InfoPopupProps = {
  anchorEl?: any;
  handlePopoverClose?: any;
};

const InfoPopup: FunctionComponent<InfoPopupProps> = ({ anchorEl, handlePopoverClose }) => {
  const classes = useStyles();

  const open = Boolean(anchorEl);

  return (
    <Popover
      id="mouse-over-popover"
      className={classes.popover}
      classes={{
        paper: classes.paper,
      }}
      open={open}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      onClose={handlePopoverClose}
      disableRestoreFocus
    >
      <Typography>Grades represent index scores:</Typography>
      <ul>
        <li>
          <Typography>
            Fundraising - A candidate's fundraising strength relative to their opponent(s), candidates in their region, and candidates like
            them
          </Typography>
        </li>
        <li>
          <Typography>Demographic - A candidate's demographic match relative to their district, and their opponent(s)</Typography>
        </li>
        <li>
          <Typography>
            Name Recognition - A candidate's name recognition in terms of incumbency, past media spending, and current media spending
          </Typography>
        </li>
        <li>
          <Typography>
            Org Support - A candidate's support from activists, endorsers, democratic organizations, PACs, and likely IE groups
          </Typography>
        </li>
      </ul>
    </Popover>
  );
};
export default InfoPopup;
