import React from 'react';

const Weights = () => {
  return (
    <ul>
      <li>
        <span>Running Average of Coefficients vs. Present Cycle Coefficients</span>
      </li>
      <ul>
        <li>
          <span>
            We give roughly equal weight to a running average of four cycles&rsquo; coefficient trends and the present cycle&rsquo;s
            coefficient trends
          </span>
        </li>
      </ul>
      <li>
        <span>Partial Cycle Coefficient Trends</span>
      </li>
      <ul>
        <li>
          <span>
            Although clear partial cycle trends exist (e.g., Black candidates, in races after June 1, 2020, largely outperformed Black
            candidates that ran before June 1, 2020, likely due to the increased visibility of and support for the Black Lives Matter
            movement following George Floyd&rsquo;s murder), we do not overfit the model by giving weight to partial-cycle trends
          </span>
        </li>
      </ul>
    </ul>
  );
};

export default Weights;
