import React from 'react';

const Project = () => {
  return (
    <ul>
      <li>Projections</li>
      <ul>
        <li>
          As current cycle data becomes available, and as soon as announced candidates file their first fundraising report, our model runs a
          candidate&rsquo;s data through our coefficient analysis to form the following projections:
        </li>
        <ul>
          <li>Vote Share</li>
          <ul>
            <li>A predicted percentage of votes cast for each candidate</li>
          </ul>
          <li>Margin of Error</li>
          <ul>
            <li>The range of plausible vote share for each candidate</li>
          </ul>
          <li>Chance of Winning</li>
          <ul>
            <li>The percent chance that each candidate has the highest vote share in a Democratic primary</li>
          </ul>
          <li>Vote Place</li>
          <ul>
            <li>On average, the expected vote place, ranked in terms of vote share, for each candidate</li>
          </ul>
        </ul>
      </ul>
    </ul>
  );
};

export default Project;
