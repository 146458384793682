import * as React from 'react';
import { Provider } from 'react-redux';
import { Store } from 'redux';
import { ApplicationState } from './redux/store';
import App from './App';
import { createMuiTheme } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';
import { BrowserRouter, Route } from 'react-router-dom';
import { BASE_ROUTE } from './constants';

interface MainProps {
  store: Store<ApplicationState>;
}

const theme = createMuiTheme({
  typography: {
    fontFamily: ["'Source Sans Pro'", 'Arial', 'sans-serif'].join(','),
  },
});

const Main: React.FC<MainProps> = ({ store }) => {
  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <BrowserRouter basename={BASE_ROUTE}>
          <Route path={`/:datasetId?/:activeRaceId?/:activeRaceDate?`} component={App} />
        </BrowserRouter>
      </Provider>
    </ThemeProvider>
  );
};
export default Main;
